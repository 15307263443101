<template>
  <b-row>
    <b-col cols="12" v-if="mainData.est_reach">
      <div class="d-flex mt-1">
        <h3 class="custom-p">
          {{ $t('profile.estimate_reach') }}
          <b-icon
            font-scale="1"
            icon="question-circle"
            class="icon-tooltip"
            id="estimate_reach"
          ></b-icon>
          <b-tooltip target="estimate_reach" triggers="hover" variant="primary">
            {{$t('profile.tooltipEstimateReach')}}
          </b-tooltip>
        </h3>
      </div>
       <h4 v-if="mainData.est_reach"><strong>{{getFormat(mainData.est_reach.from)}} - {{getFormat(mainData.est_reach.to)}} </strong></h4>
    </b-col>
    <b-col cols="6">
      <h3 class="custom-p">
        {{ $t('profile.audience_quality') }}
        <b-icon
          font-scale="1"
          icon="question-circle"
          class="icon-tooltip"
          id="estimate_reach_1"
        ></b-icon>
        <b-tooltip target="estimate_reach_1" triggers="hover" variant="primary">
          {{$t('profile.tooltipReachability')}}
        </b-tooltip>
      </h3>
      <div class="d-flex">
          <h4 class="ml-2 mb-0">
            <strong>{{$t(`instagram.${mainData.audience_reachability.title}`)}}</strong>
          </h4>
      </div>
    </b-col>

    <b-col cols="6">
      <h3 class="custom-p">
        {{ $t('profile.authenticAudience') }}
        <b-icon
          font-scale="1"
          icon="question-circle"
          class="icon-tooltip"
          id="authentic_audience"
        ></b-icon>
        <b-tooltip target="authentic_audience" triggers="hover" variant="primary">
          {{$t('profile.tooltipAuthenticity')}}
        </b-tooltip>
      </h3>
      <div class="d-flex">
        <h4 class="ml-2 mb-0"><strong>{{$t(`instagram.${mainData.audience_authenticity.title}`)}}</strong></h4>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BIcon, BTooltip } from 'bootstrap-vue';
import { getFormat } from '@/libs/utils/formats'

export default {
  components: {
    BRow,
    BCol,
    BIcon,
    BTooltip
  },
  props: {
    mainData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      getFormat,
    }
  },
}
</script>

<style type="text/css" scoped>
  .custom-p {
    font-size: 22px;
    color: #626262;
  }
</style>
